<template>
<div>
  <v-row>
    <v-snackbar
      v-model="snackbar"
      min-width="1500px"
      min-height="150px"
    >
      {{Text}}
      <template v-slot:action="{ attrs }">
        <v-btn
          color="blue"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>


    <v-col cols="12">
      <v-row>
          <v-col cols="3">
          <v-btn right color="primary" class="mb-4 me-3" @click="ClientKeys()">
            <span>Client Subscription</span>
          </v-btn>
        </v-col>
        <v-col cols="3">
          <v-btn right color="primary" class="mb-4 me-3" @click="info()">
            <span>info</span>
          </v-btn>
        </v-col>
      </v-row>
    </v-col>
    </v-row>
    <div class="pl-15 ml-15">
      <v-col cols="6">
      <br />
      <br />
      <v-text-field
        v-model="ppk"
        label="public"
        outlined
        shaped
      ></v-text-field>
      <br />
      <v-text-field
        v-model="prk"
        label="private"
        outlined
        shaped
      ></v-text-field>
      <br />
        <v-btn right color="success" class="mr-4" @click="pushSubscriptions()">
          <span>pushSubscription</span>
        </v-btn>
    </v-col>
  </div>
  </div>
</template>
<script>
//import AppCardActions from '@core/components/app-card-actions/AppCardActions.vue'
//import { mdiDeleteOutline, mdiDotsVertical, mdiEyeOutline, mdiPencilOutline, mdiPlus, mdiTrendingUp } from '@mdi/js'
// expremintal disable template support
import { NotifyAsync } from '@core/api/Chat';
import { getInfo, UpdateKeys, UpdateServerKeys } from '@core/api/User';
import { ref } from '@vue/composition-api';
export default {

  setup() {
    const ppk = ref('')
    const prk = ref('')
    const EndPoint =ref('')
    const Auth =ref('')
    const P256dh =ref('')
    const userInfo = ref({})
    const snackbar = ref(false)
    const Text =ref('')
    const publicKey = ref('')
    const privateKey = ref('')
    const webpush = require('web-push');
    const server = {
      Id: undefined,
      P256dh: '',
      EndPoint:'',
      Auth:'',
    }
    const vapidKeys = ref({})
    const PushSubscription = ref({
        "endpoint": '',
        "expirationTime": null,
          "keys": {
          "auth":   '',
          "p256dh": ''
         }
      })
    var push = require('web-push');
    const ClientKeys = () => {
      let vapidkeys = push.generateVAPIDKeys
      let vapid = vapidkeys()
      publicKey.value = vapid.publicKey
      privateKey.value = vapid.privateKey
     console.log('publickey:', publicKey.value)
     console.log('privatekey:' ,privateKey.value)
     Text.value = 'Public Key:  ' + publicKey.value + '       Private Key:  ' + privateKey.value
     snackbar.value = true
     UpdateKeys({PublicKey: publicKey.value, PrivateKey: privateKey.value})
//      push.setVapidDetails(
//       'mailto:husamnada1@yahoo.com',
//       publicKey.value,
//       privateKey.value
// );
   }
  const info = () => { getInfo().then( res => {
    userInfo.value = res
    console.log('userInfo getinfo',userInfo.value)
    Text.value = 'Private Key: ' + userInfo.value.PrivateKey + ' ' + '     Public Key: ' + userInfo.value.PublicKey + '   Endpoint: ' + userInfo.value.EndPoint + '   Auth: ' + userInfo.value.Auth + '  P256dh: ' +userInfo.value.P256dh
    snackbar.value = true
  }
  )}
const logging = v => {
  console.log('sub push', JSON.stringify(v))
}
     async function subscribe() {

       console.log('subscribe', userInfo.value.PublicKey)

         let sw = await navigator.serviceWorker.ready;
         info()
         console.log('pk', userInfo.value.PublicKey)
         let push = await sw.pushManager.subscribe({
           userVisibleOnly: true,
           applicationServerKey: userInfo.value.PublicKey,
         })
         logging(push)
         EndPoint.value = push.endpoint
         Auth.value = push.keys.auth
         P256dh.value = push.keys.p256dh
        // console.log(JSON.stringify(push));
        console.log(push)
        console.log('pushing',push.endpoint, push.keys, Auth.value, Auth.value);
          server.Auth = Auth.value
          server.P256dh =  P256dh.value
          server.EndPoint = EndPoint.value
          UpdateServerKeys(server)
         Text.value = 'pushing' + JSON.stringify(push)
       snackbar.value = true
       }

    const pushSubscriptions = () => {
      console.log('id' ,userInfo.value.Id)
      NotifyAsync(userInfo.value.Id)
  //  var script = document.createElement('script');
  //  script.src = './webpush.js';
  //  var head = document.getElementsByTagName("head")[0];
  //  head.appendChild(script);

//       vapidKeys.value.publicKey = userInfo.value.PublicKey,
//       vapidKeys.value.privateKey = userInfo.value.PrivateKey
//       console.log('vapidkeys', vapidKeys.value)
//       webpush.setVapidDetails(
//         'mailto:husamnada@hotmmail.com',
//          vapidKeys.value.publicKey,
//          vapidKeys.value.privateKey
// );
//       PushSubscription.value.endpoint = userInfo.value.EndPoint
//       PushSubscription.value.keys.auth = userInfo.value.Auth
//       PushSubscription.value.keys.p256dh = userInfo.value.P256dh
//       console.log('pushSubscription', PushSubscription.value)
//       push.sendNotification( PushSubscription.value,'Your Push Payload Text')
//       Text.value = JSON.stringify(PushSubscription.value)
//       snackbar.value = true
    }

    return {
      logging,
      server,
      ppk,
      prk,
      Text,
      userInfo,
      snackbar,
      info,
      PushSubscription,
      pushSubscriptions,
      EndPoint,
      Auth,
      P256dh,
      subscribe,
      ClientKeys,
      publicKey ,
      privateKey,
    }
  },
}
</script>

<style lang="scss">
@import '~@core/preset/preset/apps/invoice.scss';

.app-invoice-editable {
  .input-salesperson {
    width: 100px;
  }

  .select-invoice-to {
    width: 190px !important;
  }

  .header-inputs {
    width: 122px;
  }

  .add-products-form {
    .single-product-form {
      &:not(:first-child) {
        margin-top: 2rem;
      }
    }

    .header-spacer {
      // This is according to item actions width
      width: 39px;
    }
    .item-actions {
      @at-root {
        @include theme--child(add-products-form) using ($material) {
          .item-actions {
            border-left: thin solid map-deep-get($material, 'dividers');
          }
        }
      }
    }
  }
}
</style>
