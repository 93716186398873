import request from '@core/utils/RequestApi'
import qs from 'qs'

export function Send(data) {
  return request({
    url: '/Chat/Send',
    method: 'post',
    data: qs.stringify(data),
  })
}
export function NotifyAsync(data) {
  return request({
    url: '/Notfication/Send',
    method: 'post',
    data: qs.stringify(data),
  })
}
export function Create(data) {
  return request({
    url: '/Sub/Create',
    method: 'post',
    data: qs.stringify(data),
  })
}
export function Check() {
  return request({
    url: '/Notfication/Check',
    method: 'get',
  })
}
export function CreateVapid(data) {
  return request({
    url: '/Vapid/Create',
    method: 'post',
    data: qs.stringify(data),
  })
}
export function Generate() {
  return request({
    url: '/Vapid/Generate',
    method: 'post',
  })
}

